// Import dependencies
import { useEffect, useRef, useState } from "react";
// import components and configuration
import { AsideFilterLoader, TopHeader } from "./component";
import {
  AuthWrapperCM as AuthWrapper,
  TwoColumnLayout
} from "../../components";

import { useDispatch, useSelector } from "react-redux";
import { fetchDSPId, resetFetchDSPId, fetchCaseConfigByDSPId, resetCaseConfigByDSPId } from "./store/actions";
import { getToken } from "./services/dashboard-service";

import { TOKEN, getData } from "../../utils/Storage";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import { map, graph } from "../../assets/icons";
import { REDIRECT_DOMAIN_NAME } from "../../config/ApiConstants";


const Dashboard = (props: any) => {
  const iframeEle = useRef<any>(null)
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const dspCodes = useSelector((state: any) => state.dspCodesReporting);
  const caseManagementConfig = useSelector((state: any) => state.caseManagementConfig);

  const [token, setToken] = useState<any>({ isLegacy: false, token: "" });
  const [reportLinks, setReportLinks] = useState<any>(null);


  const [selectedDSP, setSelectedDSP] = useState<any>(null);
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [reportLink, setReportLink] = useState<any>(null)


  // constructor(props: any) {
  useEffect(() => {
    try {
      getData(TOKEN).then((res: any) => {
        let token;
        if (res?.provider === "msal") {
          token = { isLegacy: false, token: res.token }
        } else {
          token = { isLegacy: true, token: res.token };
        }

        setToken(token);
        // fetch dsp id
        if (selectedDSP === null && dspCodes.isFetching === false) {
          dispatch(fetchDSPId({ token: token }));
        }

      });
    } catch (e) {
      console.log(e);
    }

  }, []);

  useEffect(() => {
    try {
      if (dspCodes.isSuccess && dspCodes.data !== null) {
        setSelectedDSP(dspCodes.data.defaultSelectedValue);

        __fetchSelectedDSP(dspCodes.data.defaultSelectedValue);

        dispatch(resetFetchDSPId());
      } else if (dspCodes.isError) {
        dispatch(resetFetchDSPId());
      }
    } catch (e) {
      console.log(e);
    }
  }, [dspCodes]);


  useEffect(() => {
    if (caseManagementConfig.isSuccess && caseManagementConfig.data !== null) {
      const data = caseManagementConfig.data.panels;
      if (data && data.length > 0) {
        data.forEach((item: any) => {
          switch (item.panelId) {
            case 1:
              const flatList = item.primarySections[0].form.controls[0].treeItems.items;
              const defaultSelected = item.primarySections[0].form.controls[0].treeItems.defaultSelectedValue;

              setReportLinks(buildLookup(flatList, defaultSelected));
              break;

            // TODO: Implement this later if requried
            // case 2:
            //   setRightSidePanel(item.primarySections[0].form.controls);
            //   break;

            default:
              return null;
          }
        });
      }

      dispatch(resetCaseConfigByDSPId());
    } else if (caseManagementConfig.isError) {
      if (caseManagementConfig.data?.error?.response?.status === 404 && REDIRECT_DOMAIN_NAME !== undefined) {
        // if case management config not found redirect to case management domain
        window.location.replace(REDIRECT_DOMAIN_NAME)
      } else {
        toast.error("Something went wrong, please try again later");
      }


      dispatch(resetCaseConfigByDSPId());
    }
  }, [caseManagementConfig]);


  useEffect(() => {
    if (selectedReport !== null) {
      requireToken(selectedReport)
    }
  }, [selectedReport])

  // Convert flat list to a lookup by parentId
  const buildLookup = (items: any[], defaultSelected: any): Map<string | null, any[]> => {
    const lookup = new Map<string | null, any[]>();
    items.forEach(item => {
      if (item.id === defaultSelected) {
        setSelectedReport(item);
      }
      const children = lookup.get(item.parentId) || [];
      children.push(item);
      lookup.set(item.parentId, children);
    });
    return lookup;
  };


  const requireToken =  (item: any, redirect = false) => {
    const isTokenRequired = item?.reportLink.search("<unique_ticket>") === -1;
    let link = item?.reportLink;

    if (!isTokenRequired) {
      link = getToken({ token: token, selectedReport })
      
      link.then((res:any) => {
        if (redirect) {
          window.open(res, '_blank');
        }else {
          setReportLink(res);
        }
      })
      
    }else {
      if (redirect) {
        window.open(link, '_blank');
      } else {
        setReportLink(link);
      }
    }
  }


  const __fetchSelectedDSP = (selectedDSP: any) => {
    try {
      dispatch(fetchCaseConfigByDSPId({ dspId: selectedDSP, token: token }));
    } catch (e) {
      toast.error("Something went wrong, please try again later");
    }

  };

  const getIcon = (iconName: string) => {

    switch (iconName) {
      case "map":
        return map;

      case "graph":
        return graph;

      default:
        return ""
    }
  }

  const __onReportClick = (item: any) => {
    if (item.parentId === 0) {
      return
    }

    if (item.isEnabled) {
      setSelectedReport(item);
    } else {
      toast.error(item.disableClickMessage)
    }
  }

  const __printReport = () => {
    toast.info("Please wait while we are fetching your report")
    requireToken(selectedReport, true)
    
    // iframeEle?.current.contentWindow.print();
  }

  const __renderList = (parentId: number, lookup: any) => {
    if (reportLinks === null) {
      return '';
    }

    const children = reportLinks.get(parentId);
    if (!children || children.length === 0) {
      return '';
    }

    let html = children.map((item: any, key: number) => {
      return (<li key={key} className={item.parentId === 0 ? "parent" : "child"}>
        <div className={item?.iconName ? "d-flex" : ""}>
          {
            item?.iconName ? <div className="icon">
              <img src={getIcon(item.iconName)} alt={item.iconName} width={30} />
            </div> : null
          }
          <div className="link">
            <button
              className={"btn btn-link " + (selectedReport?.id === item.id ? "active" : "") + " " + (item.parentId === 0 ? "parent" : "child" + " " + (item.isEnabled === false ? "silveraccess" : ""))}
              onClick={() => __onReportClick(item)}
              disabled={!item.isEnabled ? false : (item.reportLink === null || item.reportLink === undefined || item.reportLink === "") && item.parentId !== 0}
            >

              <span className={item.parentId === 0 ? "bold" : ""}>{item.reportName}</span>
            </button>
          </div>
        </div>
        {__renderList(item.id, reportLinks)}
      </li>)

    })

    return <ul className="aside-report-list">{html}</ul>;
  }



  // function to render the aside filter
  const _renderFilterAside = () => {
    return (
      <div className="aside-reports-wrapper" key={11}>
        {caseManagementConfig.isFetching ? (
          <AsideFilterLoader />
        ) : __renderList(0, reportLinks)}
      </div>
    );
  };


  // function to render the section that incudes the filter and the serach bar
  const __renderMainSection = () => {
    return (
      <div className="filter-content pos-relative">
        <div className="filter-header">
          <h3>View Report {selectedReport !== null ? `- ${selectedReport.reportName}` : " "}</h3>

          <div className="button-list">
            <button className="btn btn-primary btn-border" disabled={selectedReport === null} onClick={() => __printReport()}>Print Report</button>
          </div>
        </div>
        <div className="content" style={{ minHeight: "80vh" }}>
          {
            selectedReport !== null ?
              // selectedReport.isTokenRequire ? <div className="text-center"><img src={infinityLoader} /></div> : 
              <iframe
                ref={iframeEle}
                src={reportLink}
                width="100%"
                style={{ minHeight: "80vh" }}
                frameBorder="0"></iframe> : <div className="p-3 text-center" style={{ minHeight: "80vh" }}>Select Reports to View</div>
          }
        </div>

      </div>
    )


  };

  return (
    // dashboard component
    <AuthWrapper>
      <div className="dashboard reporting">
        {/* header component  */}
        <TopHeader
          onChange={(e: any) => {
            setSelectedDSP(e.value);
            __fetchSelectedDSP(e.value);
          }}
        />

        <TwoColumnLayout
          asideTitle={"Select Reports"}
          aside={_renderFilterAside()}
          main={__renderMainSection()}
        />
      </div>
    </AuthWrapper>
  );
};

export default Dashboard;
