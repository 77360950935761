import { createBrowserRouter } from "react-router-dom";
import Error404 from "../views/error-404";
import DashboardReportingStack from "../views/dashboard-reporting";


// Create a browser router and define routes
const ReportingStack = createBrowserRouter([{
    // Root route ("/")
    path: "/",
    // Renders the Auth component when the user visits the root route
    element: <DashboardReportingStack />,
    // Renders a div with "404" when an error occurs in this route
    errorElement: <Error404 />,
},


]);

export default ReportingStack;