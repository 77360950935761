// Types for case summary config
export const CASE_SUMMARY_CONFIG_CM_REQUEST = "CASE_SUMMARY_CONFIG_CM_REQUEST";
export const CASE_SUMMARY_CONFIG_CM_RECEIVE = "CASE_SUMMARY_CONFIG_CM_RECEIVE";
export const CASE_SUMMARY_CONFIG_CM_FAILURE = "CASE_SUMMARY_CONFIG_CM_FAILURE";
export const RESET_CASE_SUMMARY_CONFIG_CM = "RESET_CASE_SUMMARY_CONFIG_CM";

// Types for case summary data
export const CASE_SUMMARY_DATA_CM_REQUEST = "CASE_SUMMARY_DATA_CM_REQUEST";
export const CASE_SUMMARY_DATA_CM_RECEIVE = "CASE_SUMMARY_DATA_CM_RECEIVE";
export const CASE_SUMMARY_DATA_CM_FAILURE = "CASE_SUMMARY_DATA_CM_FAILURE";
export const RESET_CASE_SUMMARY_DATA_CM = "RESET_CASE_SUMMARY_DATA_CM";

// Types for case data store in redux
export const CASE_DATA_CM_UPDATE = "CASE_DATA_CM_UPDATE";
export const RESET_CASE_DATA_CM = "RESET_CASE_DATA_CM";

// Types for information governance form data
export const INFORMATION_GOVERNANCE_DATA_CM_REQUEST = "INFORMATION_GOVERNANCE_DATA_CM_REQUEST";
export const INFORMATION_GOVERNANCE_DATA_CM_RECEIVE = "INFORMATION_GOVERNANCE_DATA_CM_RECEIVE";
export const INFORMATION_GOVERNANCE_DATA_CM_FAILURE = "INFORMATION_GOVERNANCE_DATA_CM_FAILURE";
export const RESET_INFORMATION_GOVERNANCE_DATA_CM = "RESET_INFORMATION_GOVERNANCE_DATA_CM";

// Types for information governance access request
export const IGAPI_DATA_REQUEST = "IGAPI_DATA_REQUEST";
export const IGAPI_DATA_RECEIVE = "IGAPI_DATA_RECEIVE";
export const IGAPI_DATA_FAILURE = "IGAPI_DATA_FAILURE";
export const RESET_IGAPI_DATA = "RESET_IGAPI_DATA";

// Types for fetch overview reference
export const FETCH_OVERVIEWREFERENCE_CM_REQUEST = "FETCH_OVERVIEWREFERENCE_CM_REQUEST";
export const FETCH_OVERVIEWREFERENCE_CM_RECEIVE = "FETCH_OVERVIEWREFERENCE_CM_RECEIVE";
export const FETCH_OVERVIEWREFERENCE_CM_FAILURE = "FETCH_OVERVIEWREFERENCE_CM_FAILURE";
export const RESET_FETCH_OVERVIEWREFERENCE_CM = "RESET_FETCH_OVERVIEWREFERENCE_CM";

// Types for fetch overview reference
export const FETCH_OVERVIEWREFERENCE_ACCESS_CM_REQUEST = "FETCH_OVERVIEWREFERENCE_ACCESS_CM_REQUEST";
export const FETCH_OVERVIEWREFERENCE_ACCESS_CM_RECEIVE = "FETCH_OVERVIEWREFERENCE_ACCESS_CM_RECEIVE";
export const FETCH_OVERVIEWREFERENCE_ACCESS_CM_FAILURE = "FETCH_OVERVIEWREFERENCE_ACCESS_CM_FAILURE";
export const RESET_FETCH_OVERVIEWREFERENCE_ACCESS_CM = "RESET_FETCH_OVERVIEWREFERENCE_ACCESS_CM";