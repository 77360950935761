import * as type from './types';
/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchCaseConfigByDSPId = (params: any) => {
    return { type: type.CASE_CONFIG_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetCaseConfigByDSPId = () => {
    return { type: type.RESET_CASE_CONFIG, payload: null }
}

/**
 * Dispatches an action to Login.
 *
 * @param {any} params - Parameters needed for passing to the login API.
 * @returns {object} - The action object.
 */
export const fetchDSPId = (params: any) => {
    return { type: type.REPORTING_GET_DSP_REQUEST, payload: params }
}


/**
 * Resets the state of Login Data.
 *
 * @returns {object} - The action object.
 */
export const resetFetchDSPId = () => {
    return { type: type.RESET_REPORTING_GET_DSP, payload: null }
}
