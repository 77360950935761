import { combineReducers } from '@reduxjs/toolkit';

// import all common reducers
import uiStateReducers from './ui-state-reducers';


// import all the reducers from the application
import caseSummaryConfigReducer from '../../views/case-summary-one-view/store/reducers/config-reducers'
import caseSummaryDataReducer from '../../views/case-summary-one-view/store/reducers/summary-data-reducers'
import caseDataReducers from '../../views/case-summary-one-view/store/reducers/case-data-reducers';
import informationGovernanceReducers from '../../views/case-summary-one-view/store/reducers/information-governance-reducers';
import igapiReducers from '../../views/case-summary-one-view/store/reducers/igapi-reducers';
import overviewreferenceReducer from '../../views/case-summary-one-view/store/reducers/fetch-overview-reference-reducers';
import overviewreferenceaccessReducer from '../../views/case-summary-one-view/store/reducers/fetch-overview-reference-access-reducers';

// import all the reducers from the application
import caseSummaryConfigReducerCM from '../../views/case-summary/store/reducers/config-reducers'
import caseSummaryDataReducerCM from '../../views/case-summary/store/reducers/summary-data-reducers'
import caseDataReducersCM from '../../views/case-summary/store/reducers/case-data-reducers';
import informationGovernanceReducersCM from '../../views/case-summary/store/reducers/information-governance-reducers';
import igapiReducersCM from '../../views/case-summary/store/reducers/igapi-reducers';
import overviewreferenceReducerCM from '../../views/case-summary/store/reducers/fetch-overview-reference-reducers';
import overviewreferenceaccessReducerCM from '../../views/case-summary/store/reducers/fetch-overview-reference-access-reducers';



import loginReducers from '../../views/auth-msal/store/reducers/login-reducers';
import passcodeReducers from '../../views/auth-msal/store/reducers/passcode-reducer';

import resetPasswordReducer from '../../views/auth-msal/store/reducers/resetpassword-reducer';
import resetPasscodeReducer from '../../views/auth-msal/store/reducers/resetpasscode-reducer';


import dspCodesReducers from "../../views/dashboard/store/reducers/get-dsp"
import caseManagementConfigReducer from "../../views/dashboard/store/reducers/case-management-config"
import getPickerListByIdReducer from "../../views/dashboard/store/reducers/pick-list"
import mainGridDataReducer from '../../views/dashboard/store/reducers/main-grid-data';
import childGridDataReducer from '../../views/dashboard/store/reducers/child-grid-data';
import cohortsGridDataReducer from '../../views/dashboard/store/reducers/cohorts-grid-data';
import editCaseDetailsReducer from '../../views/dashboard/store/reducers/edit-case-data-by-id';

import deleteRecordByIdReducer from '../../views/dashboard/store/reducers/delete-data-by-id';

import dspCodesReportingReducers from "../../views/dashboard-reporting/store/reducers/get-dsp"
// combine all reducers together
const appReducer = combineReducers({
    uiStates: uiStateReducers,
    caseSummaryConfig: caseSummaryConfigReducer,
    caseSummaryData: caseSummaryDataReducer,
    caseData: caseDataReducers,
    informationGovernanceData: informationGovernanceReducers,
    igapiData: igapiReducers,
    overviewreference: overviewreferenceReducer,
    overviewreferenceaccess: overviewreferenceaccessReducer,
    editCaseDetails: editCaseDetailsReducer,

    // Case Management Summary View
    caseSummaryConfigCM: caseSummaryConfigReducerCM,
    caseSummaryDataCM: caseSummaryDataReducerCM,
    caseDataCM: caseDataReducersCM,
    informationGovernanceDataCM: informationGovernanceReducersCM,
    igapiDataCM: igapiReducersCM,
    overviewreferenceCM: overviewreferenceReducerCM,
    overviewreferenceaccessCM: overviewreferenceaccessReducerCM,

    
    loginData: loginReducers,
    passcodeData: passcodeReducers,
    dspCodes: dspCodesReducers,
    caseManagementConfig: caseManagementConfigReducer,
    getPickerListById: getPickerListByIdReducer,
    mainGridData: mainGridDataReducer,
    childGridData: childGridDataReducer,
    cohortsGridData: cohortsGridDataReducer,
    deleteRecordById: deleteRecordByIdReducer,
    resetPassword: resetPasswordReducer,
    resetPasscode: resetPasscodeReducer,

    // Dashboard Reporting
    dspCodesReporting: dspCodesReportingReducers,
});


const rootReducer = (state: any, action: any) => {
    // if (action.type === 'IS_LOGOUT') {
    //     state = undefined
    // }
    return appReducer(state, action)
}
export default rootReducer;