import { toast } from "react-toastify";
import { getNewAxiosInstance } from "../../../utils";
import { AxiosResponse } from "axios";

const getToken = async (req: any) => {
    // Create axios instance with headers
    let ins = getNewAxiosInstance(req.token);

    let apiEndPoint = `/tableau/token`;

    // Perform GET request to fetch case summary data
    const httpResponse: AxiosResponse = await ins.get(apiEndPoint);

    if (httpResponse.status === 200) {
        return req.selectedReport.reportLink.replace("<unique_ticket>", httpResponse.data)
    }else {
        toast.error(httpResponse.statusText)
        return httpResponse
    }
}

export { getToken }