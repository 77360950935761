export default {
  translation: {
    DATA_SHARING_PROTOCALL: "Data Sharing Protocol",
    DOWNLOAD_PDF: "Download PDF",
    FILTER: "Filter",
    CASE_DETAILS_ID: "ID: ",
    CASE_DETAILS: "Case Details",
    CASES: "Cases ",
    LOG_OUT: "Log Out",
  }
}