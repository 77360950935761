import { ApiConstants } from ".";

export const caseSummaryType: any = {
  1: "ChildrensSocialCare",
  2: "SingleViewOfDebt",
};

export const MSAL_CONFIG: any = {
  auth: {
    clientId: ApiConstants.MSAL_CLIENT_ID,
    redirectUri: ApiConstants.MSAL_REDIRECT_URL,
  },
};

export const MSAL_LOGINREQUEST: any = {
  scopes: [ApiConstants.MSAL_SCOPE],
};

// data for the Create case
export const createCase: any = [
  {
    id: 1,
    name: "forename1",
    title: "Forename 1",
    type: "text",
    value: "",
  },
  {
    id: 2,
    name: "forename2",
    title: "Forename 2",
    type: "text",
    value: "",
  },
  {
    id: 3,
    name: "date",
    title: "Date of birth",
    type: "date",
    value: "",
  },

  {
    id: 4,
    name: "surname",
    title: "Surname",
    type: "text",
    value: "",
  },
  {
    id: 5,
    name: "nhsnumber",
    title: "NHS number",
    type: "text",
    value: "",
  },
  {
    id: 6,
    title: "Ethnicity",
    value: "",
    type: "select",
  },
  {
    id: 7,
    title: "Gender",
    value: "",
    type: "select",
  },
  {
    id: 8,
    title: "Language",
    value: "",
    type: "select",
  },
  {
    id: 9,
    name: "mobilephone",
    title: "Mobile phone",
    type: "text",
    value: "",
  },
  {
    id: 10,
    name: "homephone",
    title: "Home phone",
    type: "text",
    value: "",
  },
  {
    id: 11,
    name: "email",
    title: "Email",
    type: "text",
    value: "",
  },
  {
    id: 12,
    name: "houseno",
    title: "houseno",
    type: "text",
    value: "",
  },
  {
    id: 13,
    name: "addressline1",
    title: "Address line 1",
    type: "text",
    value: "",
  },
  {
    id: 14,
    name: "addressline2",
    title: "Address line 2",
    type: "text",
    value: "",
  },
  {
    id: 15,
    title: "Area",
    type: "select",
    value: "",
  },
  {
    id: 16,
    name: "postcode",
    title: "Postcode",
    type: "text",
    value: "",
  },
  {
    id: 17,
    name: "propertyType",
    title: "Property type",
    type: "text",
    value: "",
  },
];

export const asideData: any = [
  {
    id: 1,
    title: "Debt type",
    value: 0,
    type: "select",
    list: ["ASSIGN OFFICER", "CHANGE STATUS", "EDIT", "DELETE"],
  },
  {
    id: 2,
    title: "No. of debt types",
    value: 0,
    type: "select",
    list: ["ASSIGN OFFICER", "CHANGE STATUS", "EDIT", "DELETE"],
  },
  {
    id: 3,
    title: "Risks",
    value: 0,
    type: "select",
    list: ["ASSIGN OFFICER", "CHANGE STATUS", "EDIT", "DELETE"],
  },
  {
    id: 4,
    title: "Services",
    value: 0,
    type: "select",
    list: ["ASSIGN OFFICER", "CHANGE STATUS", "EDIT", "DELETE"],
  },
  {
    id: 5,
    title: "Welfare benefits",
    value: 0,
    type: "select",
    list: ["ASSIGN OFFICER", "CHANGE STATUS", "EDIT", "DELETE"],
  },
  {
    id: 6,
    title: "Geography",
    value: 0,
    type: "select",
    list: ["ASSIGN OFFICER", "CHANGE STATUS", "EDIT", "DELETE"],
  },
  {
    id: 7,
    title: "Financial resilience",
    value: 0,
    type: "select",
    list: ["ASSIGN OFFICER", "CHANGE STATUS", "EDIT", "DELETE"],
  },
  {
    id: 8,
    title: "Support status",
    value: 0,
    type: "select",
    list: ["ASSIGN OFFICER", "CHANGE STATUS", "EDIT", "DELETE", "val"],
  },
  {
    id: 9,
    title: "Economic status",
    value: 0,
    type: "select",
    list: ["ASSIGN OFFICER", "CHANGE STATUS", "EDIT", "DELETE"],
  },
  {
    id: 10,
    title: "Household type",
    value: 0,
    type: "select",
    list: ["ASSIGN OFFICER", "CHANGE STATUS", "EDIT", "DELETE"],
  },
  {
    id: 11,
    title: "Tenancy status",
    value: 0,
    type: "select",
    list: ["ASSIGN OFFICER", "CHANGE STATUS", "EDIT", "DELETE"],
  },
  {
    id: 12,
    title: "First name",
    value: "",
    name: "firstname",
    type: "text",
  },
  {
    id: 13,
    title: "Last name",
    value: "",
    name: "lastname",
    type: "text",
  },
  {
    id: 14,
    title: "Date of birth",
    value: "",
    name: "dateofbirth",
    type: "date",
  },
];

export const bottomFields: any = [
  {
    id: 18,
    name: "allocatecase",
    title: "Allocate case to",
    type: "text",
    value: "",
  },
  {
    id: 19,
    name: "casestatus",
    title: "Case status",
    type: "text",
    value: "",
  },
];

export const list: any = [
  {
    title: "GENERATE ONE",
  },
  {
    title: "GENERATE TWO",
  },
  {
    title: "GENERATE THREE",
  },
  {
    title: "GENERATE FOUR",
  },
];

export const viewDrop: any = [
  {
    title: "COMFORT",
  },
  {
    title: "COMFORT",
  },
  {
    title: "COMFORT",
  },
  {
    title: "COMFORT",
  },
];

export const perPageCount:any = [
  { value:10, title: "10" },
  { value:25, title: "25" },
  { value:50, title: "50" },
  { value:100, title: "100" },
];

export const ExportAs: any = [
  { value: "excel", title: "Excel" },
];

export const contactFormData: any = [
  {
    id: 1,
    name: "field1",
    title: "field",
    type: "select",
    value: "",
  },
  {
    id: 2,
    name: "field2",
    title: "field",
    type: "select",
    value: "",
  },
  {
    id: 3,
    name: "field2",
    title: "field",
    type: "select",
    value: "",
  },
  {
    id: 4,
    name: "field2",
    title: "field",
    type: "select",
    value: "",
  },
  {
    id: 5,
    name: "field2",
    title: "field",
    type: "select",
    value: "",
  },
  {
    id: 6,
    name: "field2",
    title: "field",
    type: "select",
    value: "",
  },
  {
    id: 7,
    name: "field2",
    title: "field",
    type: "select",
    value: "",
  },
  {
    id: 8,
    name: "field2",
    title: "field",
    type: "select",
    value: "",
  },
  {
    id: 9,
    name: "field2",
    title: "field",
    type: "select",
    value: "",
  },
  {
    id: 10,
    name: "field2",
    title: "field",
    type: "select",
    value: "",
  },
];

export const dropDownData: any = [{ id: 1, value: "test1", title: "test 1" },
{ id: 2, value: "test2", title: "test 2" },
{ id: 3, value: "test3", title: "test 3" },
{ id: 4, value: "test4", title: "test 4" }]
