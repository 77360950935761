import React, { useEffect, useState } from "react";
import { selectFieldProps } from "../../interfaces";

const DropDown: React.FC<selectFieldProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Ensure selectedValue is always an array to support multiple selections
  const [selectedValue, setSelectedValue] = useState<any[]>([]);
  const [data, setData] = useState<any[]>(props.data || []);

  useEffect(() => {
    setData(props.data || []);
  }, [props.data]);

  useEffect(() => {
    if (props.defaultSelected !== null && props.defaultSelected !== undefined) {
      const defaultSelectedValues = Array.isArray(props.defaultSelected) ? props.defaultSelected : [props.defaultSelected];
      const selectedItems = props.data.filter((item: any) => defaultSelectedValues.includes(item.value));

      setSelectedValue([...selectedItems]);
    }
  }, [props.defaultSelected]);

  const __openDropdown = () => {
    setIsOpen(!isOpen);
  };

  const __selectOption = (e: any, item: any) => {
    e.preventDefault();

    if (props.isMultiSelectPickList) {
      // Check if item is already selected
      const index = selectedValue.findIndex((selectedItem) => selectedItem.value === item.value);
      if (index > -1) {
        // Remove item if already selected
        setSelectedValue(selectedValue.filter((_, i) => i !== index));
      } else {
        // Add item to selected values
        setSelectedValue([...selectedValue, item]);
      }
    } else {
      setSelectedValue([item]);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (selectedValue.length > 0 && selectedValue.every((val) => val.value !== "")) {
      // If multiselect, pass the entire array, otherwise pass the single selected item

      if (!props.isMultiSelectPickList) {
        props.onChange(selectedValue[0]);
      } else {
        props.onChange(selectedValue);
      }
    }
  }, [selectedValue]);

  return (
    <>
      <div className="filter-fields">
        {props.labelExternal && (
          <>
            <h3 className="title">{props.label}</h3>
          </>
        )}
        <div className={isOpen ? "select-container open" : "select-container"} data-ismultiselect={props.isMultiSelectPickList}>
          <div className="select-button-wrapper">
            <button onClick={__openDropdown} type="button">
              {/* Render selected item titles or default label if none */}
              {selectedValue.length
                ? selectedValue.map((val) => val.title).join(", ")
                : props.label}
            </button>
          </div>

          {isOpen && (
            <ul className="dropdown-list">
              {data.length ? (
                data.map((item, index) => (
                  <li
                    key={index}
                    className={
                      selectedValue.find((val) => val.value === item.value) ? "item active" : "item"
                    }
                  >
                    <button onClick={(e) => __selectOption(e, item)} type="button">
                      {item.title}
                    </button>
                  </li>
                ))
              ) : (
                <div>Fetching...</div>
              )}
            </ul>
          )}
        </div>
      </div>

      {isOpen && (
        <div
          onClick={__openDropdown}
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10000,
          }}
        ></div>
      )}
    </>
  );
};

export default DropDown;